import React, { useState, useEffect } from 'react';
import '../../pages/Style.scss';
import { JoinedCampaignAction, MyCampaignViewDetailsAction, RequestCampaignStatusAction } from '../../redux/Slices/CampaignSlice';
import { useLocation, useNavigate, useParams ,Link} from 'react-router-dom';
import { CommonModal } from '../../components/Modal/CommonModal';
import DeleteCampaigns from '../../components/Modal/DeleteCampaigns';
import { GrDownload } from "react-icons/gr";
const CampaingnsView = () => {
    const { id } = useParams();
    const { state } = useLocation()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [campaignDetailData, setCampaignDetailData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [searchString, setSearchString] = useState("");

    const [JoinCampaignsModalShow, setJoinCampaignsModalShow] = useState(false)
    const [JoinCampaignID, setJoinCampaignID] = useState();

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            "_id": id
        }
        MyCampaignViewDetailsAction(payload, (response) => {
            if (response?.status === true) {
                setCampaignDetailData(response?.data)
            } else {
            }
        })();
    }, [MyCampaignViewDetailsAction, perPageCount, currentPage, statusUpdate, searchString, id])

    const JoinedCampaing = () => {
        setLoader(true)
        JoinedCampaignAction({ campaign_id: JoinCampaignID }, (response) => {
            if (response?.status === true) {
                setJoinCampaignID("")
                setJoinCampaignsModalShow(false)
                setStatusUpdate(statusUpdate + 1)
            }
            setLoader(false)
        })();
    }

    const handleRequestStatusUpdate = (id, status) => {
        RequestCampaignStatusAction({ _id: id, status }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
                navigate("/campaigns")
            }
            setLoader(false)
        })();
    }
    return (
        <>
            {/* <div className='top_heading_button'>
                <h1 className='main_heading_top'>Campaign View21222222312</h1>
            </div> */}

            <div className='new_Campaigns_create_section new_Campaigns_view common_tab_section p-0 mt-0'>
                <div className='view_img'>
                    <img src={campaignDetailData?.view?.marketing_assets ? campaignDetailData?.view?.marketing_assets : "-"} alt="" />
                    <div className='join_view_campaigns'>
                        {
                        // state?.mode == "browse-campaigns" ?
                        //     <button
                        //         type='button'
                        //         className='btn_blue'
                        //         onClick={() => {
                        //             setJoinCampaignsModalShow(true)
                        //             setJoinCampaignID(campaignDetailData?.view?._id)
                        //         }}
                        //         disabled={campaignDetailData?.view?.joined == 1 || campaignDetailData?.view?.joined == 3}
                        //     >
                        //         {campaignDetailData?.view?.joined == 0 ? "Join"
                        //             : campaignDetailData?.view?.joined == 1 ? "Joined"
                        //                 : campaignDetailData?.view?.joined == 2 ? "Request to join"
                        //                     : campaignDetailData?.view?.joined == 3 ? "Pending"
                        //                         : ""
                        //         }
                        //     </button>
                        //     :
                            state?.mode == "request" ?
                                campaignDetailData?.view?.campaign_approve == 1 || campaignDetailData?.view?.campaign_approve == 2 ?
                                    null
                                    :
                                    <>
                                        <button
                                            type='button'
                                            className='table_btn reject view_page_btn'
                                            onClick={() => handleRequestStatusUpdate(state?.id, 2)}
                                        >
                                            Reject
                                        </button>
                                        <button
                                            type='button'
                                            className='table_btn approve view_page_btn'
                                            onClick={() => handleRequestStatusUpdate(state?.id, 1)}
                                        >
                                            Approve
                                        </button>
                                    </>
                                : campaignDetailData ?
                                <button
                                type='button'
                                className='btn_blue'
                                onClick={() => {
                                    setJoinCampaignsModalShow(true)
                                    setJoinCampaignID(campaignDetailData?.view?._id)
                                }}
                                disabled={campaignDetailData?.view?.joined == 1 || campaignDetailData?.view?.joined == 3}
                            >
                                {campaignDetailData?.view?.joined == 0 ? "Join"
                                    : campaignDetailData?.view?.joined == 1 ? "Joined"
                                        : campaignDetailData?.view?.joined == 2 ? "Request to join"
                                            : campaignDetailData?.view?.joined == 3 ? "Pending"
                                                : ""
                                }
                            </button>
                            : null
                        }
                    </div>
                </div>

                <div className='campaings_content_view'>

                    <div className='campaigns_name'>
                        <div>
                            {campaignDetailData?.view?.campaign_name ? campaignDetailData?.view?.campaign_name : "-"}
                        </div>
                        <div className='price_text'>
                            $ {campaignDetailData?.view?.ppc_rate ? campaignDetailData?.view?.ppc_rate : "-"}
                        </div>
                    </div>
                    <div className='content_view_complete'>
                        <div className='text_description'>
                            {campaignDetailData?.view?.description}
                        </div>

                        <div className='heading_text'>
                            Types of product(s) :
                        </div>
                        <p className='text_description'> {campaignDetailData?.view?.product_type?.type_name ? campaignDetailData?.view?.product_type?.type_name : "-"} </p>

                        <div className='heading_text'> Remaining Credit :</div>
                        <p className='text_description'> {campaignDetailData?.view?.no_of_credits ? parseFloat(campaignDetailData?.view?.no_of_credits(2)) : "0"}  </p>

                        <div className='heading_text'> Tags (Ex:- abc, bgc) :</div>
                        <p className='text_description'> {campaignDetailData?.view?.tags ?
                            JSON.parse(campaignDetailData?.view?.tags)?.map((value, key) =>
                                <span key={key}> {value} </span>
                            )
                            : ""
                        } </p>
                        <div className='heading_text'>
                            Target Link :
                        </div>
                        <p className='text_description'> {campaignDetailData?.view?.target_link ? campaignDetailData?.view?.target_link : "-"} </p>
                        <div className='heading_text'>
                            Advanced options :
                        </div>
                        <p className='text_description'> {campaignDetailData?.view?.advanced_option ?
                            <div>
                                {campaignDetailData?.view?.advanced_option == 3 ? "Link Invite Only" :
                                    campaignDetailData?.view?.advanced_option == 4 ? "Manual Invite Only" :
                                        campaignDetailData?.view?.advanced_option == 2 ? "Approved Only" :
                                            "Public"
                                }
                            </div>
                            :
                            "-"
                        }
                        </p>
                        {campaignDetailData?.view?.marketing_assets_multiple && campaignDetailData?.view?.marketing_assets_multiple.length > 0 && (
                        <div className='heading_text'>
                            Marketing Assets:
                            <div className='five_upload_img'>
                                {JSON.parse(campaignDetailData?.view?.marketing_assets_multiple)?.map((item) => {
                                    const fileExtension = item.key?.split('.').pop();
                                    const isImage = ["png", "jpg", "jpeg"].includes(fileExtension);
                                    const isPDF = fileExtension === "pdf";
                                    const isDOC = ["doc", "docx"].includes(fileExtension);
                                    const isPPT = fileExtension === "ppt";

                                    return (
                                        <div key={item.key}>
                                            {isImage && (
                                                <Link to={item.Location} target="_blank" download>
                                                    <img src={item.Location} className="img-fluid" referrerPolicy="no-referrer" alt={item.Location} />
                                                </Link>
                                            )}
                                            {isPDF && (
                                                <Link to={item.Location} target="_blank" download>
                                                    <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
                                                </Link>
                                            )}
                                            {isDOC && (
                                                <Link to={item.Location} target="_blank" download>
                                                    <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/word_icon.svg" alt="DOC" />
                                                </Link>
                                            )}
                                            {isPPT && (
                                                <Link to={item.Location} target="_blank" download>
                                                    <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/microsoft_icon.svg" alt="PPT" />
                                                </Link>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>

            <CommonModal
                show={JoinCampaignsModalShow}
                onHide={() => {
                    setJoinCampaignsModalShow(false)
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Do you want to join the Campaign?"
                        onClickButton1={() => {
                            setJoinCampaignsModalShow(false)
                        }}
                        onClickButton2={() => JoinedCampaing()}
                        loader={loader}
                        btn1="No"
                        btn2="Yes"
                    />
                }
            />
        </>
    )
}

export default CampaingnsView;