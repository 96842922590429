import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { ReportsManagementAction } from '../../../redux/AdminSlices/Common';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Pagination } from "../../../components/Hooks/Pagination"
import { DownloadFileInNewWindow, HasConsecutiveSpaces, debounceSearch } from '../../../utils/Function';
import { useNavigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { Button } from '../../../components/Common/Button';

function ReportsManagementListing() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchString, setSearchString] = useState("");
    const [downloadLoader, setDownloadLoader] = useState(false)

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
        }
        setLoader(true)
        ReportsManagementAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [ReportsManagementAction, perPageCount, currentPage, searchString])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    const handleDownloadExcel = () => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            download: "csv"
        }
        setDownloadLoader(true)
        ReportsManagementAction(payload, (response) => {
            if (response?.status === true) {
                DownloadFileInNewWindow(response?.data?.url)
            }
            setDownloadLoader(false)
        })();
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Reports Management </h1>
                <Button
                    className="btn_blue"
                    text="Download Excel"
                    type="submit"
                    onClick={handleDownloadExcel}
                    loader={downloadLoader}
                    disabled={downloadLoader}
                />
            </div>

            <div className='admin_list_table'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='request_campaigns_list'>
                    <div className='content_campaigns_user table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> manager name </th>
                                    <th> campaign name   </th>
                                    <th> No. of markets Joined </th>
                                    <th> Product type </th>
                                    <th> Tags  </th>
                                    <th> Target link </th>
                                    <th>  PPC rate  </th>
                                    <th> Remaining Credits </th>
                                    <th> Total Paid  </th>
                                    <th className='text-end'> Action </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !loader ?
                                        listDataArr?.data?.campaign_list?.length > 0 ?
                                            listDataArr?.data?.campaign_list?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                    <td> {value?.user_name ? value?.user_name : ""}</td>
                                                    <td> {value?.campaign_name ? value?.campaign_name : "-"} </td>
                                                    <td> {value?.join_campaign_count} </td>
                                                    <td> {value?.product_type_name ? value?.product_type_name : "-"} </td>
                                                    <td>
                                                        <p className="tags_line_table">
                                                            {value?.tags ?
                                                                JSON.parse(value?.tags)?.map((data, index) => (
                                                                    index < 2 ?
                                                                        <span> {data} </span>
                                                                        :
                                                                        index == 3 ? <span> +{JSON.parse(value?.tags).length - 2} </span>
                                                                            :
                                                                            null
                                                                ))
                                                                : ""
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className='target_link_table'>
                                                        <a href={value?.target_link} className='text-ellipsis' target='_blank'> {value?.target_link} </a>
                                                    </td>
                                                    <td> {value?.ppc_rate ?  parseFloat(value?.ppc_rate.toFixed(2)) : "-"} </td>
                                                    <td className='text-center'>${value?.remaining_credits ? parseFloat(value?.remaining_credits.toFixed(2)) : 0}</td>
                                                    <td className='text-center'>${value?.total_ppc ? parseFloat(value?.total_ppc.toFixed(2)) : 0}</td>
                                                    <td className='text-end fixed-side'>
                                                        <button type='button' onClick={() => navigate(`/campaigns-management/detail/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`)} className='common_btn_admin edit_btn_admin'>
                                                            <FaEye />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={11}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan={11}>Loading...</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                            </div>

                            {listDataArr?.data?.campaign_list?.length > 0 ?
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={listDataArr?.data?.pagination?.totalItems}
                                    pageSize={listDataArr?.data?.pagination?.perPage}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsManagementListing;